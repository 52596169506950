.lunch-image {
  max-width: 266px;
  width: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.third-col {
  box-sizing: border-box;
  margin-bottom: 1rem;
  position: relative;
}

.text-overlay {
  position: absolute;
  left: 0;
  bottom: 5px;
  background: rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  color: white;
}

.lunch-title {
  margin: 0;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.125rem;
  line-height: 1.4;
}

@media (min-width: 480px) {
  .flex-row {
    display: flex;
    justify-content: space-between;
  }

  .third-col:not(:first-child) {
    margin-left: 1rem;
  }
}
