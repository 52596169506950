html,
body {
  padding: 0;
  margin: 0;
}

/* 
 * Text
 */

html {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgb(121, 121, 121);
  background: rgba(210, 210, 210, 0.2);
}

strong {
  font-weight: 600;
}

.h1,
.h2,
.h3 {
  margin: 0.5rem 0 0;
}

.h1 {
  font-size: 2rem;
  font-weight: 200;
  text-transform: uppercase;
}

.h2 {
  font-size: 2rem;
  font-weight: 200;
  text-transform: uppercase;
}

.h3 {
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: none;
}

.text {
  font-size: 1rem;
  letter-spacing: 0.02rem;
  max-width: 26rem;
  line-height: 1.4rem;
  margin: 1rem 0 0;
}

/**
 * Sections
 */

.section-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100vh;
}

.section-full.peek {
  min-height: calc(100vh - 5.25rem);
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 2rem;
  box-sizing: border-box;
}

.button-group {
  display: flex;
  justify-content: space-around;
}

.button-group .button:not(:last-child) {
  margin-right: 1rem;
}

/**
 * Button
 */
.button {
  appearance: none;
  border: none;
  border-radius: 2px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin-top: 2rem;
  transition: background 200ms, border-color 200ms;
}

.button:not(.secondary) {
  color: white;
  background: #1e1e1e;
  border: 0.125rem solid #1e1e1e;
}

.button:not(.secondary):hover {
  background: #828282;
  border-color: #828282;
}

.button.secondary {
  color: #1e1e1e;
  background: white;
  border: 0.125rem solid #828282;
}

.button.secondary:hover {
  border-color: #1e1e1e;
}

@media (min-width: 768px) {
  .section-cards {
    display: flex;
    justify-content: center;
  }

  .card {
    max-width: 400px;
    min-height: 33rem;
  }
}
