.about-graphics {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-bottom: 0.5rem;
}

.notebook {
  width: 50px;
  height: 80px;
  background: rgba(230, 225, 200);
  border-left: 1px solid rgb(200, 170, 135);
  border-radius: 2px;
  box-sizing: border-box;
}

.notebook-cover {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgb(230, 220, 190), rgba(230, 225, 200));
  transform: rotate(-1deg);
  position: relative;
  left: 4%;
  border: 1px solid rgb(230, 220, 190);
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 0.4rem;
  padding: 5%;
  color: rgb(0, 0, 145);
}

.notebook-prongs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 5%;
  margin-bottom: 0.4rem;
}

.prong-wrapper {
  width: 5%;
  height: 100%;
  background: rgb(52, 44, 35);
  transform: scale(1);
  opacity: 0.8;
}

.prong-wrapper:after {
  content: "";
  display: block;
  border-left: 1px solid rgb(122, 122, 122);
  border-right: 1px solid rgb(122, 122, 122);
  height: 200%;
  width: 20%;
  position: relative;
  top: -190%;
}
