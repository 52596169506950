.intro-graphics {
  display: flex;
  align-items: flex-end;
  position: relative;
  left: 0.625rem;
}

/**
 * Laptop
 */

.laptop {
  width: 180px;
  height: 106px;
  display: flex;
  flex-direction: column;
  align-items: center;
  perspective: 400px;
}

.laptop-base {
  position: relative;
  width: 100%;
  height: 4.7%;
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
  border-bottom-left-radius: 15% 80%;
  border-bottom-right-radius: 15% 80%;
  background: #828282;
  background: linear-gradient(to top, #828282 65%, #e6e6e6 75% 100%);
  border: 1px solid #828282;
}

.laptop-display {
  border-radius: 2% 2% / 4% 4%;
  background: #e9e9e9;
  width: 84.03%;
  height: 97.64%;
  position: relative;
  top: 2%;
  border: 1px solid #838488;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0% 100%;
  transform: rotateX(12deg) translateZ(-15px);
}

.laptop-bezel {
  width: 99%;
  height: 98%;
  border-radius: 2% / 3%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.laptop-screen {
  width: 90%;
  height: 85%;
  background: #1e1e1e;
  color: white;
  font: 0.6rem monospace;
  padding-left: 3%;
  box-sizing: border-box;
  overflow: hidden;
}

.laptop-screen-content {
  position: relative;
  top: -0.125rem;
}

.code {
  margin: 0;
  line-height: 1.2;
  opacity: 0.5;
}

/**
 * Teacup
 */

.teacup {
  width: 35px;
  height: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.bowl {
  width: 100%;
  height: 80%;
  border-top-left-radius: 3%;
  border-top-right-radius: 3%;
  border-bottom-left-radius: 30% 95%;
  border-bottom-right-radius: 30% 95%;
  background: linear-gradient(to top, #a0b4b4, #dce9e9 20% 100%);
  border-top: 1px solid #8c7855;
}

.foot {
  width: 43%;
  height: 20%;

  border-bottom: 1px solid #a0b4b4;
  background: linear-gradient(to bottom, #a0b4b4, #dce9e9 70% 100%);
}
